/* CustomDatePicker.css */
.custom-date-picker .ant-picker-input {
  /* Hide the year arrows */
  display: none;
}
.ant-picker-panel-container {
  border: 1px solid #36ae78 !important;
}
.ant-table-filter-dropdown .ant-picker {
  margin-right: 0px !important;
}

.ant-table-filter-dropdown {
  border: 1px solid#36ae78 !important;
}
.ant-picker-content thead {
  display: none;
}
.ant-picker-footer {
  display: none;
}
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-cell-in-view .ant-picker-cell-inner {
  background-color: rgb(239, 236, 236);
}
.ant-picker-cell:hover .ant-picker-cell-inner {
  background-color: #90ccb1 !important; /* Change this to the desired background color */
}
.ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #36ae78 !important; /* Change this to the desired background color */
  border: #36ae78 !important;
}
.ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner {
  border: none !important; /* Change this to the desired border color */
}

/* ant buttons */
.ant-btn {
  background-color: #ffffff !important;
  color: #000000 !important;
  font-size: 100%;
  font-weight: inherit !important;
  line-height: inherit;
  padding-top: 0.5rem;
  height: auto !important;
  padding-bottom: 0.5rem;
}
.ant-table-filter-trigger.active {
  color: #36ae78 !important;
}
.ant-picker-dropdown {
  transition: none !important;
}

/* Custom Checkbox Styles */
.custom-checkbox .ant-checkbox-inner {
  border-color: #36ae78 !important;
  background-color: #36ae78 !important;
}
.custom-checkbox .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #36ae78 !important;
  background-color: #36ae78 !important;
}
.custom-checkbox .ant-checkbox-input {
  background-color: #36ae78 !important;
  font-size: 16px!important;

}
.custom-checkbox:checked {
  accent-color: rgb(54 174 120 ); /* Tailwind's green-400 color */
}
.scroller, Menu.scroller {
margin-left: 10px;
  overflow-y: scroll;
  scrollbar-color: rgba(54, 174, 120, 0.714) white!important;
}
.ant-dropdown-menu-item  {
  font-size: 17px!important;
}
