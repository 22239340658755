/* CSS */
.checkbox-container {
    position: relative;
    display: inline-block;
    width: 1.5rem; 
    height: 1.5rem; 
  }
  .modal {
    visibility: hidden;
    /* Other styles for modal */
  }
  
  .modal-visible {
    visibility: visible;
  }
   
  .checkbox-container input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline: none;
    border: none;
    background-color: transparent;
  }
  
  .checkbox-container input[type="checkbox"]::before {
    content: "";
    position: absolute;
    top: 0.25rem; 
    left: 0.25rem; 
    width: 1rem; 
    height: 1rem; 
    background-color: transparent;
    border: 1px solid #28c981; 
    border-radius: 0.25rem; 
    box-shadow: 0 0 2px 0px #28c981;
  }
  
 
  .checkbox-container input[type="checkbox"]:checked::before {
    content: "\2713";
    color: white; 
    font-size: 1rem; 
    text-align: center;
    line-height: 1.25rem; 
    background-color: #36AE78;
  }
  
  /* CSS */
.checkbox-pen {
  position: relative;
  display: flex;
  gap: 1.7rem;
  
}

.checkbox-pen input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  border: none;
  background-color: transparent;
}

.checkbox-pen input[type="checkbox"]::before {
  content: "";
  position: absolute;
  top: 0.25rem; 
  left: 0.25rem; 
  width: 1rem; 
  height: 1rem; 
  background-color: transparent;
  border: 1px solid #36AE78; 
  border-radius: 0.25rem; 
  box-shadow: 0 0 2px 0px #36AE78;
}


.checkbox-pen input[type="checkbox"]:checked::before {
  content: "\2713";
  color: white; 
  font-size: 1rem; 
  text-align: center;
  line-height: 1.25rem; 
  background-color: #36AE78;
}

