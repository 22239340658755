@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: 'Poppins';
}

.custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    border-radius: 60px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #DFF2E9;
    border-radius: 10px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #6EC59D;
  }
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }


/* custom scrollbar for popups */

.modal-container-outer {
  overflow: hidden; 
  border-radius: 40px; 
  background-clip: padding-box; 
}

.modal-container-inner {
  overflow-y: auto; 
  max-height: 100%;
  padding-right: 15px;
}

.modal-container-inner::-webkit-scrollbar {
  width: 10px; 
}

.modal-container-inner::-webkit-scrollbar-track {
  background: transparent; 
}

.modal-container-inner::-webkit-scrollbar-thumb {
  background: #36AE78; 
  border-radius: 10px; 
}

.modal-container-inner::-webkit-scrollbar-thumb:hover {
  background: #36AE78; 
}
