/* CustomDatePicker.css */
.custom-date-picker .ant-picker-input {
  /* Hide the year arrows */
  display: none;
}
.ant-picker-panel-container {
  border: 1px solid #36ae78 !important;
}
.ant-table-filter-dropdown .ant-picker {
  margin-right: 0px !important;
}

.ant-table-filter-dropdown {
  border: 1px solid#36ae78 !important;
}
.ant-picker-content thead {
  display: none;
}
.ant-picker-footer {
  display: none;
}
.ant-picker-header-super-prev-btn,
.ant-picker-header-super-next-btn {
  display: none;
}
.ant-picker-cell-in-view .ant-picker-cell-inner {
  background-color: rgb(239, 236, 236);
}
.ant-picker-cell:hover .ant-picker-cell-inner {
  background-color: #90ccb1 !important; /* Change this to the desired background color */
}
.ant-picker-cell.ant-picker-cell-selected .ant-picker-cell-inner {
  background-color: #36ae78 !important; /* Change this to the desired background color */
  border: #36ae78 !important;
}
.ant-picker-cell.ant-picker-cell-today .ant-picker-cell-inner {
  border: none !important; /* Change this to the desired border color */
}

/* ant buttons */
.ant-btn {
  background-color: #36ae78 !important;
  color: #fff !important;
}
.ant-table-filter-trigger.active {
  color: #36ae78 !important;
}
.ant-picker-dropdown {
  transition: none !important;
}
